.card {
  margin-bottom: 16px;
}

.ck-dropdown__panel {
  height: 160px;
  width: 50px;
  max-height: 365px;
  overflow-x: hidden;
  padding: auto;
  margin: auto;
}

.ck.ck-list__item .ck-button .ck-button__label {
  line-height: unset !important;
}

.ck.ck-tooltip.ck-tooltip_s {
  transform: translateY(-100%) !important;
  display: none !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  padding: 0 !important;
}

.cdk-overlay-container {
  z-index: 999999 !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@font-face {
    font-family: "DIN W05 Light";
    src: url('../src/assets/fonts/DINPro-Light.otf')format("opentype");
}
@font-face {
    font-family: "DIN W05 Regular";
    src: url('../src/assets/fonts/DINPro.otf')format("opentype");
}
@font-face {
    font-family: "DIN W05 Regular Italic";
    src: url('../src/assets/fonts/DINPro-Italic.otf')format("opentype");
}
@font-face {
    font-family: "DIN W05 Medium";
    src: url('../src/assets/fonts/DINPro-Medium.otf')format("opentype");
}
@font-face {
    font-family: "DIN W05 Bold";
    src: url('../src/assets/fonts/DINPro-Bold.otf')format("opentype");
}
@font-face {
    font-family: "DIN W05 Black";
    src: url('../src/assets/fonts/DINPro-Black.otf')format("opentype");
}
@font-face {
    font-family: "DIN W05 Cond Bold";
    src: url('../src/assets/fonts/DINPro-CondBold.otf')format("opentype");
}
@font-face {
    font-family: "Inter-Regular";
    src: url('../src/assets/fonts/Inter-Regular.otf')format("opentype");
}
@font-face {
    font-family: "Inter-Bold";
    src: url('../src/assets/fonts/Inter-Bold.otf')format("opentype");
}
@font-face {
    font-family: "Inter-Semi-Bold";
    src: url('../src/assets/fonts/Inter-SemiBold.otf')format("opentype");
}
@font-face {
    font-family: "Inter-Medium";
    src: url('../src/assets/fonts/Inter-Medium.otf')format("opentype");
}
@font-face {
    font-family: "Inter-Light";
    src: url('../src/assets/fonts/Inter-Light-BETA.otf')format("opentype");
}
@font-face {
    font-family: "Inter-Italic";
    src: url('../src/assets/fonts/Inter-Italic.otf')format("opentype");
}

.mat-tooltip {
  font-size: small !important;
} 

